<template>
    <div class="px-0 flex-column h-100">
        <div class="px-0 d-flex align-self-start flex-column flex-grow-0 h-100">
            <div class="d-flex flex-row flex-shrink-0 p-2">
                <h6 class="mb-0 ms-1 text-body">{{ $t('Workflows') }}</h6>
                <span class="ms-auto">
                    <ODataLookup :data-object="local_dsWFItemsLkp"
                        :bind="(sel: DataItemModel) => { addRelatedWorkflow(sel) }" contextField="OrgUnitIdPath" :whereClause="dsWFItemsLkpWhereclause()">
                        <OColumn field="ID" width="80" v-slot="{ row }">
                            <span :class="{ 'text-decoration-line-through': row.Closed }">{{ row.ID }}</span>
                        </OColumn>
                        <OColumn field="Title" :headerName="$t('Title')" width="400" />
                        <OColumn field="OrgUnit" :headerName="$t('Org Unit')" width="230" />
                        <OColumn field="CreatedBy" :headerName="$t('Created by')" width="250" />
                        <template #target="{ target }">
                            <button class="btn btn-link text-decoration-none py-0" :ref="target">
                                <i class="bi bi-share" aria-hidden="true"></i> {{ $t('Connect existing workflow') }}
                            </button>
                        </template>
                    </ODataLookup>
                </span>
            </div>
            <ODataGrid :data-object="local_dsMeasures" hideGridMenu class="py-0" loadDataObject hideMultiselectColumn
                style="max-height:250px;min-height:250px;" ref="relatedWF_Grid">
                <OColumn field="ScopeItem_ID" width="65" sortable read-only :headerName="$t('ID')"
                    :headerTitle="$t('Workflow ID')"
                    :cellrendererparams="{ href: '/nt/workflow?ID={{ScopeItem_ID}}', target: '_blank' }"
                    v-slot="{ row, column }">
                    <OLink :modelValue="row" :column="column" :class="{ 'text-decoration-line-through': row.Closed }">
                    </OLink>
                </OColumn>
                <OColumn field="Title" :headerTitle="$t('Title')" :headerName="$t('Title')" width="285" sortable
                    read-only />
                <OColumn field="Step" :headerTitle="$t('Step')" :headerName="$t('Step')" width="200" sortable read-only />
                <OColumn field="StepResp" :headerTitle="$t('Step Responsible')" :headerName="$t('Step Responsible')"
                    width="213" sortable read-only />
                <!-- <OColumn field="Process" :headerTitle="$t('Process')" :headerName="$t('Process')" hide sortable read-only /> -->
                <OColumn field="DueDate" :headerTitle="$t('Due date')" :headerName="$t('Due date')" hide sortable
                    read-only />
                <!-- <OColumn field="CatAorB" :headerTitle="$t('Category A or B')" :headerName="$t('Category A or B')" hide -->
                <!-- sortable read-only /> -->
                <!-- <OColumn field="IsPunchItem" :headerTitle="$t('Is Punch Item')" :headerName="$t('Is Punch Item')" hide sortable read-only /> -->
                <!-- <OColumn field="RelatedObjects" :headerTitle="$t('Related objects')" :headerName="$t('Related objects')" hide sortable read-only /> -->
                <!-- <OColumn field="Conclusion" :headerTitle="$t('Conclusion')" :headerName="$t('Conclusion')" hide sortable read-only /> -->
                <OColumn field="CreatedBy" :headerTitle="$t('Created by name')" :headerName="$t('Created by')" hide sortable
                    read-only />
                <OColumn field="Created" :headerTitle="$t('Created date')" :headerName="$t('Created date')" hide sortable
                    read-only />
                <OColumn field="ClosedBy" :headerTitle="$t('Closed by name')" :headerName="$t('Closed by')" hide sortable
                    read-only />
                <OColumn field="Closed" :headerTitle="$t('Closed')" :headerName="$t('Closed')" hide sortable read-only />
            </ODataGrid>

            <OTabs class="mt-2">
                <OTab :title="$t('Workflow details')" active>
                    <iframe ref="iFrame" class="w-100 h-100" style="min-height:100%;" v-if="!isLoading && currentWF"
                        :src="`/nt/scope-workflow?ID=${currentWF}&HideNav=true`"></iframe>
                </OTab>
                <OTab :title="$t('Create new')">
                    <div class="p-2" v-if="!renderCreateNewApp">
                        <span class="fw-bold">{{ $t('Promoted processes') }}</span>
                        <div class="d-flex justify-content-center" v-if="local_dsPromotedProcesses.state.isLoading">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="mb-2">
                            <button class="btn btn-sm btn-outline-primary mx-1 my-1"
                                v-for="(process, index) in local_dsPromotedProcesses.data" :process_id="process.Process_ID"
                                :key="index" @click="(e: Event) => { createWorkflow(e) }">
                                {{ $t(process.Process) }}
                            </button>
                        </div>
                        <span>
                            {{ $t('Select one of the above [promoted/default] process or click') }}
                            <button class="btn btn-sm btn-outline-primary mx-1" process_id="null"
                                @click="(e: Event) => { createWorkflow(e) }">
                                {{ $t('Create workflow') }}
                            </button>
                            {{ $t('to use another process') }}
                        </span>
                    </div>
                        <iframe class="w-100 border-0 flex-grow-1" v-if="renderCreateNewApp" style="min-height: 100%;" :src="newWorkflowUrl">
                        </iframe>
                </OTab>
            </OTabs>
        </div>
    </div>
</template>

<script setup lang="ts">
import { getOrCreateDataObject, type DataItemModel, type DataObjectModels } from 'o365-dataobject';
import { getOrCreateProcedure, IProcedureOptions,  } from 'o365-modules';
import { onMounted, ref, watch, onUnmounted } from 'vue';
import { useDataObjectEventListener } from 'o365-vue-utils';
import { $t } from 'o365-utils';
import { confirm as confirmDialog } from 'o365-vue-services';
// import { API } from 'o365-modules';
// import IFrameComp from 'o365.vue.components.DetailsIframe.vue';

const procConnectWorkflow = getOrCreateProcedure<IProcedureOptions>({ id: "procConnectWorkflow", procedureName: "astp_Workflow_ItemsConnect" });
const procConnectScopeItem = getOrCreateProcedure<IProcedureOptions>({ id: "procConnectScopeItem", procedureName: "astp_RAMS_AddScopeItem" });
const procRemoveScopeItemFromRisk = getOrCreateProcedure<IProcedureOptions>({id: "procRemoveScopeItemFromRisk", procedureName: "astp_RAMS_RemoveScopeItemFromRisk"})

const riskChanged = ref(false);

const props = defineProps({
    "riskID": {
        type: Number,
        required: true
    },
    "riskOrgUnitID": {
        type: Number,
        required: true
    },
    "risk": {
        type: String,
        required: true
    }
});

const isLoading = ref<boolean>(false);
const relatedWF_Grid = ref<HTMLDivElement | null>(null);
const iFrame = ref<HTMLIFrameElement | null>(null);
const currentWF = ref<number | null>(null);
const newWorkflowUrl = ref<string | null>(null);
const renderCreateNewApp = ref<boolean>(false);

let idPath_fieldName: (string | null) = null;
let local_dsMeasures: (DataObjectModels | null) = null;
let local_dsWFItemsLkp: (DataObjectModels | null) = null;

watch(props, (newVal, oldVal) => {
    if (newVal.riskID != oldVal.riskID) {
        console.log(newVal.riskID)
        local_dsMeasures.whereClause = `Risk_ID = ${props.riskID}`
        local_dsMeasures.load();
    }
    riskChanged.value = true;
})

local_dsMeasures = getOrCreateDataObject<DataObjectModels>({
    id: 'dsMeasures' + crypto.randomUUID(),
    viewName: "aviw_RAMS_ScopeItems",
    uniqueTable: 'atbv_RAMS_ScopeItems',
    maxRecords: 25,
    selectFirstRowOnLoad: true,
    allowUpdate: false,
    allowInsert: false,
    allowDelete: true,
    whereClause: "Risk_ID = " + props.riskID,
    fields:
        [
            { name: "Created", type: "date" },
            { name: "Risk_ID", type: "int" },
            { name: "ScopeItem_ID", type: "int" },
            { name: "Closed", type: "date" },
            // {name: "ID", type: "int" },
            { name: "Title", type: "string" },
            { name: "Step", type: "string" },
            { name: "Severity", type: "string" },
            { name: "StepResp", type: "string" },
            { name: "OrgUnit_ID", type: "int" },
            { name: "OrgUnit", type: "string" },
            { name: "CreatedBy", type: "string" },
            { name: "ClosedBy", type: "string" },
            { name: "DueDate", type: "date" },
            // { name: "CatAorB", type: "string" }
        ]
});

// When changing which WF to see details for, message is posted for making only the WF datasource refresh and not the whole iframe
useDataObjectEventListener(local_dsMeasures, 'CurrentIndexChanged', () => {
    isLoading.value = true;
    currentWF.value = local_dsMeasures.current.ScopeItem_ID;
    console.log("curr WF ID: " + currentWF.value)
    if (currentWF.value) {
        console.log("før post")
        // await iFrame?.value?.post({
        // workflowId: currentWF.value
        // });
    }
    console.log("Changed!")
    isLoading.value = false;
})

useDataObjectEventListener(local_dsMeasures, 'DataLoaded', () => {
    if (local_dsMeasures.data.length === 0) { currentWF.value = null; }
});

local_dsWFItemsLkp = getOrCreateDataObject<DataObjectModels>({
    id: 'dsWFItemsLkp' + crypto.randomUUID(),
    viewName: "aviw_Scope_Workflows",
    maxRecords: 25,
    fields:
        [
            { name: "ID", type: "int" },
            { name: "Closed", type: "date" },
            { name: "OrgUnit", type: "string" },
            { name: "CreatedBy", type: "string" },
            { name: "Title", type: "string" },
            { name: "OrgUnitIdPath", type: "string" }
        ]
})

local_dsMeasures.on('BeforeDelete', async (options, row) => {
    options.cancelEvent = true;
    await confirmDialog({
        message: $t("This action will delete the connection to") + ' "' + props.risk + '"',
        title: $t("Delete connection to RAMS element?"),
    }).then( async () => {
        await procRemoveScopeItemFromRisk.execute({
            Risk_ID: props.riskID, 
            Item_ID: row.ScopeItem_ID,
        }).then(() => {
            local_dsMeasures.load();
        }).catch((ex: any) => {
            alert(ex);
        })
    }).catch( () => {
        return;
    })
});

const dsWFItemsLkpWhereclause = () => {
    const processIds = local_dsPromotedProcesses.data
    .map((Process) => Process.Process_ID)
    .join(local_dsPromotedProcesses.data.length === 1 ? "" : ", ");

    return 'Process_ID IN ( ' + processIds + ')'
}

const local_dsPromotedProcesses = getOrCreateDataObject<DataObjectModels>({
    id: 'dsPromotedProcesses' + crypto.randomUUID(),
    viewName: 'aviw_RAMS_Processes',
    maxRecords: 25,
    fields:
        [
            { name: "ID", type: "int" },
            { name: "Process_ID", type: "date" },
            { name: "Process", type: "string" },
            { name: "Closed", type: "string" }
        ]
})

const eventMethod: string = window.addEventListener ? "addEventListener" : "attachEvent";
const eventer: Function = window[eventMethod];
const messageEvent: string = eventMethod == "attachEvent" ? "onmessage" : "message";

const addRelatedWorkflow = async (workflow: DataItemModel) => {
    await procConnectWorkflow.execute({
        Item_ID: workflow.ID,
        Rams_ID: props.riskID,
    }).then(() => {
        local_dsMeasures.load();
    });
}

onMounted(async () => {
    await local_dsPromotedProcesses.load();
})

// Listen to message from child window
onMounted(() => {
    const eventListener = async function(e: Event) {
        const data = e["data"]; 
        const regex = /^scopeID<(\d+)>$/;
        const match = data.match(regex);
        
        let scopeID : Number;
        
        if (match && !riskChanged.value) {
            scopeID = parseInt(match[1]);
                        
            try {
                await procConnectScopeItem.execute({
                    ScopeItem_ID: scopeID,
                    Risk_ID: props.riskID, 
                });
                await local_dsMeasures.load();
                renderCreateNewApp.value = false;
            } catch (error) {
                console.error(`Failed to connect ScopeID ${scopeID} to RiskID ${props.riskID}:`, error);
            }
            
        } else if (data === 'workflowCancelled') {
            renderCreateNewApp.value = false;
        }
    };

    window.addEventListener('message', eventListener);
    
    onUnmounted(() => {
        window.removeEventListener('message', eventListener);
    });
});

const createWorkflow = (e: Event) => {
    const target = e.target as HTMLButtonElement;
    const process_value: string | null = target.getAttribute('process_id');

    if (process_value !== 'null') {
        newWorkflowUrl.value = `/nt/scope-workflow-new?process-id=${process_value}&Context=${props.riskOrgUnitID}&Redirect=postmessage&HideNav=true`;
    } else {
        newWorkflowUrl.value = `/nt/scope-workflow-new?Context=${props.riskOrgUnitID}&Redirect=postmessage&HideNav=true`;
    }

    renderCreateNewApp.value = true;
}

</script>